import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import { combineReducers } from 'redux';
import moment from 'moment';
import isEqual from 'lodash.isequal';
import { getItemById, serialize } from 'utils';
import { getWorkTypesSelector } from 'modules/workTypes';
import { getFieldsSelector } from 'modules/fields';
import { getCropsSelector } from 'modules/crops';
import { getFilterSeasonSelector } from 'modules/globalFilters';
import { getSowing } from 'utils/getSowingCropId';
import getFieldAreaInfo from 'utils/getFieldAreaInfo';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemConstants,
  createCommonItemReducer,
} from './utils';

export const FORM_NAME = 'ControlWorksForm';

const actions = {
  CONTROL_WORK_RECORDS: 'CONTROL_WORK_RECORDS',
  CONTROL_WORK_RECORDS_SEARCH_TEXT: 'CONTROL_WORK_RECORDS_SEARCH_TEXT',
  TOGGLE_FILTERS: 'TOGGLE_FILTERS',
  CLEAR_FILTERS: 'CLEAR_FILTERS',
  SET_FILTER: 'SET_FILTER',
};

const constantsControlWorkrecords = createCommonListConstants(
  actions.CONTROL_WORK_RECORDS
);

const contstantsControlWorkrecordsSearchText = createCommonItemConstants(
  actions.CONTROL_WORK_RECORDS_SEARCH_TEXT
);

export const getInitialFilters = () => ({
  crop: null,
  workType: null,
});

export const getInitialState = () => ({
  isOpenFilters: false,
  filters: getInitialFilters(),
});

export const commonReducer = (state = getInitialState(), action) => {
  switch (action.type) {
    case actions.TOGGLE_FILTERS:
      return {
        ...state,
        isOpenFilters:
          typeof action.payload === 'boolean'
            ? action.payload
            : !state.isOpenFilters,
      };
    case actions.CLEAR_FILTERS:
      return {
        ...state,
        filters: getInitialFilters(),
      };
    case actions.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default combineReducers({
  workrecords: createCommonListReducer(constantsControlWorkrecords),
  searchText: createCommonItemReducer(contstantsControlWorkrecordsSearchText),
  common: commonReducer,
});

export function getControlWorkRecords(params) {
  return saveData(
    `${urlsWrapper('workrecords')}?${serialize(params)}`,
    constantsControlWorkrecords.SET_LIST,
    constantsControlWorkrecords.SET_IS_PENDING
  );
}

export const getControlWorkRecordsSelector = createSelector(
  [
    (state) => state.controlWorkrecords.workrecords.list,
    (state) => state.controlWorkrecords.workrecords.isPending,
  ],
  (list, isPending) => ({
    list,
    isPending,
  })
);

export const setSearchText = (payload) => ({
  type: contstantsControlWorkrecordsSearchText.SET_ITEM,
  payload,
});

export const getSearchTextSelector = createSelector(
  [(state) => state.controlWorkrecords.searchText.item],
  (searchText) => searchText
);

export const getControlWorksFormSelector = createSelector(
  [(state) => state.form?.[FORM_NAME]?.values],
  (value) => value
);

export const getControlWorksFormDateSelector = createSelector(
  [getControlWorksFormSelector],
  (formValues) => formValues?.date
);

export const getControlWorksFormSeasonSelector = createSelector(
  [getControlWorksFormDateSelector],
  (formValues) =>
    moment(formValues?.date).isValid()
      ? moment(formValues?.date).year()
      : moment().year()
);

export const setToggleFilters = (payload) => ({
  type: actions.TOGGLE_FILTERS,
  payload,
});

export const setClearFilters = (payload) => ({
  type: actions.CLEAR_FILTERS,
  payload,
});

export const setFilter = (payload) => ({
  type: actions.SET_FILTER,
  payload,
});

export const getIsOpenFiltersSelector = createSelector(
  [(state) => state.controlWorkrecords.common.isOpenFilters],
  (isOpenFilters) => isOpenFilters
);

export const getFiltersSelector = createSelector(
  [(state) => state.controlWorkrecords.common.filters],
  (filters) => filters
);

const getDescription = (field, crops, season) => {
  if (!field) return '';
  const sowing = getSowing(field, season);
  const crop = getItemById(crops, sowing?.crop_id);
  const cropInfo = crop == null ? 'Культура не указана' : crop.name;
  return [`${cropInfo}${getFieldAreaInfo(field, true)}`];
};

export const getControlWorksSelector = createSelector(
  [
    getControlWorkRecordsSelector,
    getWorkTypesSelector,
    getFieldsSelector,
    getCropsSelector,
    getFilterSeasonSelector,
  ],
  (
    { list: works },
    { list: workTypes },
    { list: fields },
    { list: crops },
    season
  ) => {
    return works.map((w) => {
      const field = fields.find(({ id }) => id === w.field_id);
      const sowing = getSowing(field, season);
      const currentSowing = getSowing(field, field?.season);
      return {
        ...w,
        workType: getItemById(workTypes, w.work_type_id),
        field: fields.find(({ id }) => id === w.field_id),
        description: getDescription(field, crops, season),
        sowing,
        crop: getItemById(crops, sowing?.crop_id),
        currentSowing,
        currentCrop: getItemById(crops, currentSowing?.crop_id),
      };
    });
  }
);

export const getFilteredControlWorksSelector = createSelector(
  [getControlWorksSelector, getFiltersSelector],
  (works, filters) => {
    const { crop, workType } = filters;
    if (!crop && !workType) return works;

    return works.filter((item) => {
      let cropMatch = true;
      let workTypeMatch = true;

      if (crop) {
        cropMatch = item?.currentCrop?.id === crop;
      }
      if (workType) {
        workTypeMatch = item?.workType?.work_type_group_value === workType;
      }

      return cropMatch && workTypeMatch;
    });
  }
);

export const getIsChangedFiltersSelector = createSelector(
  [getFiltersSelector],
  (filters) => {
    return !isEqual(filters, getInitialFilters());
  }
);

export const getIsPendingControlWorksSelector = createSelector(
  [
    getControlWorkRecordsSelector,
    getWorkTypesSelector,
    getFieldsSelector,
    getCropsSelector,
  ],
  (works, workTypes, fields, crops) => {
    return (
      works.isPending ||
      workTypes.isPending ||
      fields.isPending ||
      crops.isPending
    );
  }
);
